/*.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  position: relative;
  flex: 0 0 100%;
}
.embla_img{
    max-height: 15rem;
}*/

.embla {
  position: relative;
  background-color: transparent;
  padding: 20px;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  /*display: flex;*/
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 30%;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla__slide {
  position: relative; 

  /*min-width: 50%;*/
  padding-left: 10px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 290px;
}

.embla__link {  
}
.embla__slide__img {
  object-fit: contain;
  /*grid-area: image;  */
  width: 100%;
  min-height: 100%;

  /*transform: translate(-50%, -50%);*/
}
.embla__info{
  position: absolute;
}
.embla__title {
  position: absolute;
  bottom: 10px;
  background-color: black;
  padding: 10px;
  color: white;
  grid-area: title;
  font-size: 18px;
}



.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}

@media (max-width: 486px) {
  .embla__container {
    display: flex;
    /*display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 30%;*/
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -10px;
  }
  
  .embla__slide {
    flex: 0 0 100%;
    position: relative;
    /*min-width: 50%;*/
    padding-left: 10px;
  }
}

/* For small devices (596px and up) */

@media only screen and (min-width: 596px) {}

/* For medium devices (720px and up) */

@media only screen and (min-width: 720px) {}

/* For large devices (995px and up) */

@media only screen and (min-width: 995px) {}

/* For extra large devices (1100px and up) */

@media only screen and (min-width: 1100px) {}