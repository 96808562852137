* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.padding {
  padding: 5rem;
}

.link {
  color: black;
  text-decoration: none;
}

body {
  background-color: rgb(0, 0, 0);
  color: white;
}

#headline {
  font-size: 50px;
}

#hero {
  padding: 8rem 0;
  text-align: center;
}

#profile {
  border-radius: 50%;
}

#about-me {
  margin: 3rem 0 0 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}
#about-me-desc {
  margin: 1rem;
  font-size: 25px;
}

#skills {
  font-size: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: ". left . right .";
}

#skills h1 {
  margin-bottom: 25px;
}
#left {
  grid-area: left;
}

#right {
  grid-area: right;
}

#projects {
  margin: 1rem 0;
}

#certifications {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: ". front . js .";
}
.certLink {
  text-decoration: none;
  color: white;
}

.certificate {
  width: 30rem;
}

.certificate img {
  width: 100%;
}

#front {
  grid-area: front;
}

#jsAlgo {
  grid-area: js;
}

form {
  display: grid;

  /*grid-template-rows: repeat(1fr, 6);
  grid-template-areas:
    "label"
    "name"
    "label"
    "email"
    "label"
    "textarea";*/
  margin-top: 3rem;
}

#contact-msg{
  margin-bottom: 7rem;
}
.field {
  background-color: rgba(27, 27, 165, 0.603);
  outline: none;
  border: 1px solid rgb(147, 147, 168);

  margin: 1rem;
  font-size: 17px;
  color: white;
  min-width: 15rem;
}
textarea {
  min-height: 10rem;
}

#sbmt {
  margin-top: 2rem;
  padding: 1rem;
  font-size: 18px;
  color: white;
  border-radius: 5%;
  border: 1px solid white;
  background-color: rgb(10, 30, 48);
}

/* For very small devices (486px and below) */

@media only screen and (max-width: 486px) {
}

/* For small devices (596px and up) */

@media only screen and (min-width: 596px) {
}

/* For medium devices (720px and below) */

@media only screen and (max-width: 720px) {
  #certifications {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .certificate {
    width: 15rem;
    margin: 20px;
  }

  .certificate img {
    width: 100%;
  }
  .padding {
    padding: 5px;
  }
}

/* For large devices (721px and up) */

@media only screen and (min-width: 721px) {
  .certificate {
    width: 20rem;
    margin: 20px;
  }
  .padding {
    padding: 5px;
  }
}

/* For extra large devices (1100px and up) */

@media only screen and (min-width: 1100px) {
  .padding {
    padding: 2rem;
  }
}
