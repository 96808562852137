.background--custom {
    background: linear-gradient(90deg, #2B2B80, #242424);
    background-size: 300% 300%;
    animation: gradient 4s alternate infinite;
    /*position: absolute;
    top: 0;
    left: 0;*/
    height: 100%;
    width: 100%;
  }
  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
  